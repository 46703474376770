import React, { Component } from 'react';
import StringArrayInput from './StringArrayInput';
import { AppContext } from '../context';
import _ from 'lodash';
import Moment from 'moment';

import helpersClass from "./helpers";
const helpers = new helpersClass();

export default class ConfigOptionInput extends Component {
    static contextType = AppContext;

    render() {
        const content = this.context.config[this.props.name];
        const displayName = _.startCase(this.props.name);

        if (Array.isArray(content)) {
            if (content.some(item => typeof item !== 'string')) {
                return (
                    <div className="content">
                        <h3 className="title is-4">{displayName}</h3>
                        {Object.keys(content).map((optionName, i) => {
                            return (
                                <div key={i} className="field">
                                    <AppContext.Provider value={{
                                        ...this.context,
                                        config: content,
                                        handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat([this.props.name], path === undefined ? [] : path)),
                                    }}>
                                        <ConfigOptionInput name={optionName} />
                                    </AppContext.Provider>
                                </div>
                            );
                        })}
                    </div>
                );
            }

            return (
                <div className="content">
                    <h3 className="title is-4">{displayName}</h3>
                    <AppContext.Provider value={{
                        ...this.context,
                        content,
                        // handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat([this.props.name], path === undefined ? [] : path)),
                        // handleArrayKeyDown: (e, path) => this.context.handleArrayKeyDown(e, [].concat([this.props.name], path === undefined ? [] : path)),
                    }}>
                        <StringArrayInput name={this.props.name} />
                    </AppContext.Provider>
                </div>
            );
        }
        if (typeof content == 'object') {
            return (
                <div className="content">
                    <h3 className="title is-4">{displayName}</h3>
                    {Object.keys(content).map((optionName, i) => {
                        return (
                            <AppContext.Provider key={i} value={{
                                ...this.context,
                                config: content,
                                handleInputChange: (e, path) => this.context.handleInputChange(e, [].concat(this.props.name, path === undefined ? [] : path)),
                                refreshStringArray: (path, focus) => this.context.refreshStringArray([].concat([this.props.name], path === undefined ? [] : path), focus),
                            }}>
                                <div className="field">
                                    <ConfigOptionInput name={optionName} />
                                </div>
                            </AppContext.Provider>
                        );
                    })}
                </div>
            );
        }
        if (typeof content == 'boolean') {
            return (
                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" name={this.props.name} checked={this.context.config[this.props.name]}
                                onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                            />&nbsp;
                            {displayName}
                        </label>
                    </div>
                </div>
            );
        }
        if (typeof content == 'string') {
            if (helpers.isDateString(content)) {
                return (
                    <div className="field">
                        <h3 className="title is-4">{Moment(content).format("ddd, MMM D")}</h3>
                        {/* <label className="label">{displayName}</label> */}

                        {/* <div className="control">
                            <DatePicker
                                className="input has-background-grey-lighter has-text-dark"
                                name={this.props.name}
                                selected={new Date(content)}
                                onChange={(value, e) => this.context.handleInputChange({ target: { value: value.toUTCString() }}, [this.props.name])}
                            />
                        </div> */}
                    </div>
                );
            }

            return (
                <div className="field">
                    <label className="label">{displayName}</label>
                    <div className="control">
                        <input className="input" type="text" name={this.props.name} value={content}
                            onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                        />
                    </div>
                </div>
            );
        }
        if (typeof content == 'number') {
            return (
                <div className="field">
                    <label className="label">{displayName}</label>
                    <div className="control">
                        <input className="input" type="number" name={this.props.name} value={content}
                            onChange={(e) => this.context.handleInputChange(e, [e.target.name])}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className="control has-text-danger">
                The option "{this.props.name}" cannot be displayed because the "{typeof content}" type is not supported.
            </div>
        );

    }
}