import React, { Component } from 'react';
import { AppContext } from '../context';

export default class StringArrayInput extends Component {
    static contextType = AppContext;

    render() {
        const content = this.context.config[this.props.name];
        return (
            <div className="content">
                {content.map((value, i) => {
                    return (
                        <div key={i} className="field">
                            <label className="label"></label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    name={this.props.name}
                                    value={value}
                                    onChange={(e) => this.context.handleInputChange(e, [this.props.name, i])}
                                    onKeyDown={(e) => this.context.refreshStringArray([this.props.name, i])}
                                    onFocus={(e) => this.context.refreshStringArray([this.props.name, i])}
                                    onBlur={(e) => this.context.refreshStringArray([this.props.name, i], false)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}