import moment from "moment";
import Moment from "moment";

import _ from 'lodash';

export default class helpers {
    getRandomKey() {
        let n = Math.floor(Math.random() * 999999999999).toString();
        return new Date().getTime().toString() + "_" + n;
    }

    formatTime(date) {
        let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
        return formattedDate.replace("+00:00", "Z");
    }

    calculateDuration(startTime, endTime) {
        // snag parameters and format properly in UTC
        let end = moment.utc(endTime).format("DD/MM/YYYY HH:mm:ss");
        let start = moment.utc(startTime).format("DD/MM/YYYY HH:mm:ss");

        // calculate the difference in milliseconds between the start and end times
        let difference = moment(end, "DD/MM/YYYY HH:mm:ss").diff(
            moment(start, "DD/MM/YYYY HH:mm:ss")
        );

        // convert difference from above to a proper momentJs duration object
        let duration = moment.duration(difference);

        return (
            Math.floor(duration.asHours()) + moment.utc(difference).format(":mm")
        );
    }

    buildUrl(event, type, isCrappyIE) {
        let calendarUrl = "";

        // allow mobile browsers to open the gmail data URI within native calendar app
        // type = (type == "google" && this.isMobile()) ? "outlook" : type;

        switch (type) {
            case "google":
                calendarUrl = "https://calendar.google.com/calendar/render";
                calendarUrl += "?action=TEMPLATE";
                calendarUrl += "&dates=" + this.formatTime(event.startTime);
                calendarUrl += "/" + this.formatTime(event.endTime);
                calendarUrl += "&location=" + encodeURIComponent(event.location);
                calendarUrl += "&text=" + encodeURIComponent(event.title);
                calendarUrl += "&details=" + encodeURIComponent(event.description);
                break;

            case "yahoo":
                // yahoo doesn't utilize endTime so we need to calulate duration
                let duration = this.calculateDuration(event.startTime, event.endTime);
                calendarUrl = "https://calendar.yahoo.com/?v=60&view=d&type=20";
                calendarUrl += "&title=" + encodeURIComponent(event.title);
                calendarUrl += "&st=" + this.formatTime(event.startTime);
                calendarUrl += "&dur=" + duration;
                calendarUrl += "&desc=" + encodeURIComponent(event.description);
                calendarUrl += "&in_loc=" + encodeURIComponent(event.location);
                break;

            case "outlookcom":
                calendarUrl = "https://outlook.live.com/owa/?rru=addevent";
                calendarUrl += "&startdt=" + this.formatTime(event.startTime);
                calendarUrl += "&enddt=" + this.formatTime(event.endTime);
                calendarUrl += "&subject=" + encodeURIComponent(event.title);
                calendarUrl += "&location=" + encodeURIComponent(event.location);
                calendarUrl += "&body=" + encodeURIComponent(event.description);
                calendarUrl += "&allday=false";
                calendarUrl += "&uid=" + this.getRandomKey();
                calendarUrl += "&path=/calendar/view/Month";
                break;

            default:
                calendarUrl = [
                    "BEGIN:VCALENDAR",
                    "VERSION:2.0",
                    "BEGIN:VEVENT",
                    "URL:" + document.URL,
                    "DTSTART:" + this.formatTime(event.startTime),
                    "DTEND:" + this.formatTime(event.endTime),
                    "SUMMARY:" + event.title,
                    "DESCRIPTION:" + event.description,
                    "LOCATION:" + event.location,
                    "END:VEVENT",
                    "END:VCALENDAR"
                ].join("\n");

                if (!isCrappyIE && this.isMobile()) {
                    calendarUrl = encodeURI(
                        "data:text/calendar;charset=utf8," + calendarUrl
                    );
                }
        }

        return calendarUrl;
    }

    // determine if a mobile browser is being used
    isMobile() {
        let mobile = false;

        (function(a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                mobile = true;
        })(window.navigator.userAgent || window.navigator.vendor || window.opera);

        return mobile;
    }

    isDateString = (s) => {
        return s.endsWith('Z') || s.includes('GMT-');
    }

    setStartDate = (value) => {
        // console.log({value});
        const date = Moment(value).startOf('day').utc().toISOString();
        // console.log({date});
        // const date = Moment().utc().startOf('day').toDate();
        // console.log({date});
        // const date2 = new Date(date.setUTCHours(0, 0, 0, 0));
        // console.log({date2});
        // console.log((new Date(value)).setUTCHours(0, 0, 0, 0));
        return date;
        // const startDate = Moment((new Date()).setUTCHours(0, 0, 0, 0))
    }

    getPath = (obj, path) => {
        var result = obj;
        path.forEach(key => result = result[key]);
        return result;
    };

    setPath = (obj, path, value) => {
        if (path.length > 1) {
            this.setPath(obj[path[0]], path.slice(1), value);
        } else {
            obj[path[0]] = value;
        }
    };

    removePath = (obj, path) => {
        if (path.length > 1) {
            this.removePath(obj[path[0]], path.slice(1));
        } else {
            const n = parseInt(path[0], 10);
            if (!_.isNaN(n)) {
                _.pullAt(obj, n);
            }
        }
    };

    parseJSONParam = (paramJSON) => {
        return JSON.parse(JSON.parse(paramJSON))
    }

    fixArray = (a) => {
        return a.filter(item => item !== '').concat(['']);
    }

    fixArrayPath = (obj, path) => {
        if (path.length > 2) {
            this.fixArrayPath(obj[path[0]], path.slice(1));
        } else {

        }
    }

    trimIndex = (a) => {
        const n = parseInt(a[a.length - 1], 10);
        if (!_.isNaN(n)) {
            return a.slice(0, -1);
        }
        return a;
    }

    samePath = (pathA, pathB) => {
        // console.log({pathA, pathB});
        if (pathA === undefined || pathB === undefined) {
            return false;
        }
        return _.isEqual(this.trimIndex(pathA), this.trimIndex(pathB));
    }

    refreshStringArrayReal = (path, focus = true) => {
        let pathWithFocus;
        if (focus) {
            pathWithFocus = this.trimIndex(path);
        } else {
            pathWithFocus = undefined;
        }
        this.setState((previousState) => {
            const newState = { ...previousState, pathWithFocus };
            path = path.slice(0, -1);
            let value = this.getPath(newState.ourData, path);
            value = value.filter(item => item !== '');
            if (this.samePath(path, pathWithFocus)) {
                value = value.concat(['']);
            }
            this.setPath(newState.ourData, path, value);
            return newState;
        });
    }

    normalize = (string) => {
        return string.toLowerCase().replace(' ', '_');
    }

    combineData = (ourData, theirData, name) => {
        // Shallow copy is fine since we are using recursion
        const newData = {...ourData};
        for (let [key, value] of Object.entries(newData)) {
            if (typeof value == 'object') {
                newData[key] = this.combineData(value, theirData !== undefined && theirData[key] ? theirData[key] : undefined, name);
            } else if (typeof value == 'boolean') {
                const existingData = [].concat(theirData !== undefined && theirData[key] ? theirData[key] : []);
                newData[key] = value ? existingData.concat([name]) : existingData;
            }
        }
        return newData;
    }

    newDto = () => {
        return {
            times: [
                // Example
                // ["2020-04-20T22:00:00.000Z",["Steve", "Bill"]]
            ]
        };
    };

    mergeDataArray = (dataArray) => {
        console.log({dataArray});
        const dto = this.newDto();
        // const baseData = dataArray[0];

        const result = Object.assign({}, dataArray[0], dataArray[1] !== undefined ? dataArray[1] : {});
        // dto.times.forEach((e) => {
        //     data.times[e[0]] = e[1];
        // });

        return result;
    }

    datePlusTime = (date, timeInt) => {
        // console.log({date, timeInt});
        const result = Moment(new Date((new Date(date)).valueOf() + timeInt*60*60000));
        return result;
    }
    // const endTime = Moment(new Date(startTime.valueOf() + 1*60*60000));

    toDto = (data) => {
        const dto = this.newDto();

        // console.log({data});
        // console.log(data.dates);
        if (data.dates !== undefined) {
            _.forEach(data.dates, (day) => {
                // console.log(day.date);
                _.forEach(day.times, (participants, time) => {
                    // console.log({t: time, p: participants});
                    if (participants && participants.length) {
                        dto.times.push(
                            [
                                this.datePlusTime(day.date, parseInt(time)).toISOString(),
                                // this.datePlusTime(day.date, parseInt(time) + 1),
                                participants
                            ]
                        );
                    }
                });
            });
        }
        // console.log(data.dates);
        return dto;
    }

    fromObjectToDto = (o) => {
        console.log({o});
        const dto = this.newDto();
        Object.keys(o.times).forEach((k) => {
            dto.times.push(
                [
                    k,
                    // this.datePlusTime(day.date, parseInt(time) + 1),
                    o[k]
                ]
            );
        });
        return dto;
    }

    fromDto = (dto) => {
        if (!Array.isArray(dto.times)) {
            console.error('DTO malformed', dto);
            console.error('DTO malformed', typeof dto.times);
            return {};
        }
        const data = {
            times: {
                // Examples:
                // '2020-04-20T08:00:00.000Z': ['Steve'],
                // '2020-04-20T10:00:00.000Z': ['Bill', 'Steve']
            }
        };

        dto.times.forEach((e) => {
            data.times[e[0]] = e[1];
        });


        console.log({dto});
        console.log('data');
        console.log(data);
        return data;
    }

    urlEncodeJSON = (json) => {
        // return JSON.stringify(this.toDto(json));
        return encodeURIComponent(JSON.stringify(JSON.stringify(json)));;
    }
}
