import React, { Component } from 'react';
import './App.css';
import 'bulma';
import ConfigOptionInput from './components/ConfigOptionInput';
import { AppContext } from './context';
import Textarea from 'react-textarea-autosize';
import netlifyIdentity from 'netlify-identity-widget';
import _ from 'lodash';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const netlifyAuth = {
    isAuthenticated: false,
    user: null,
    authenticate(callback) {
        this.isAuthenticated = true;
        netlifyIdentity.open();
        netlifyIdentity.on('login', user => {
            this.user = user;
            callback(user);
        });
    },
    signout(callback) {
        this.isAuthenticated = false;
        netlifyIdentity.logout();
        netlifyIdentity.on('logout', () => {
            this.user = null;
            callback();
        });
    }
};

const yaml = require('js-yaml');
const config = require('./default-data.json');

export default class App extends Component {
    constructor(props) {
        super(props);

        const yaml = '';
        const json = '';
        this.state = {
            yaml,
            json,
            config,
            currentTab: 'form',
            formOnly: false,
            processModalIsOpen: false,
            pathWithFocus: undefined,
        };
        this.yaml = React.createRef();
        this.json = React.createRef();
        this.refreshStringArray = _.debounce(this.refreshStringArrayReal);
    }

    componentDidMount = () => {
        let json = '';

        const urlParams = new URLSearchParams(window.location.search);
        const sharedJSON = urlParams.get('json');
        const formOnly = urlParams.get('formOnly');
        const savedJSON = localStorage.getItem('config');

        if (formOnly) {
            this.setState({
                formOnly: true,
            });
        }
        if (sharedJSON) {
            json = JSON.parse(sharedJSON);
        } else if (savedJSON) {
            json = savedJSON;
        }

        if (json) {
            this.setState({
                config: JSON.parse(json),
            });
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    saveState = () => {
        localStorage.setItem('config', JSON.stringify(this.state.config));
    }

    componentWillUpdate = () => {

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (yaml.safeDump(this.state.config) !== this.state.yaml) {
            this.setState({
                yaml: yaml.safeDump(this.state.config),
            });
        }
        if (JSON.stringify(this.state.config) !== this.state.json) {
            this.setState({
                json: JSON.stringify(this.state.config),
            });
        }
        this.saveState();
    }

    getPath = (obj, path) => {
        var result = obj;
        path.forEach(key => result = result[key]);
        return result;
    };

    setPath = (obj, path, value) => {
        if (path.length > 1) {
            this.setPath(obj[path[0]], path.slice(1), value);
        } else {
            obj[path[0]] = value;
        }
    };

    removePath = (obj, path) => {
        if (path.length > 1) {
            this.removePath(obj[path[0]], path.slice(1));
        } else {
            const n = parseInt(path[0], 10);
            if (!_.isNaN(n)) {
                _.pullAt(obj, n);
            }
        }
    };

    handleInputChange = (e, path) => {
        if (path === undefined) {
            path = [e.target.name];
        }
        const value = e.target.value;
        const checked = e.target.checked;
        this.setState((previousState) => {
            const newState = { ...previousState };
            this.setPath(newState.config, path, typeof this.getPath(newState.config, path) == 'boolean' ? checked : value);
            return newState;
        });
    }

    fixArray = (a) => {
        return a.filter(item => item !== '').concat(['']);
    }

    fixArrayPath = (obj, path) => {
        if (path.length > 2) {
            this.fixArrayPath(obj[path[0]], path.slice(1));
        } else {

        }
    }

    trimIndex = (a) => {
        const n = parseInt(a[a.length - 1], 10);
        if (!_.isNaN(n)) {
            return a.slice(0, -1);
        }
        return a;
    }

    samePath = (pathA, pathB) => {
        console.log({pathA, pathB});
        if (pathA === undefined || pathB === undefined) {
            return false;
        }
        return _.isEqual(this.trimIndex(pathA), this.trimIndex(pathB));
    }

    refreshStringArrayReal = (path, focus = true) => {
        let pathWithFocus;
        if (focus) {
            pathWithFocus = this.trimIndex(path);
        } else {
            pathWithFocus = undefined;
        }
        this.setState((previousState) => {
            const newState = { ...previousState, pathWithFocus };
            path = path.slice(0, -1);
            let value = this.getPath(newState.config, path);
            value = value.filter(item => item !== '');
            if (this.samePath(path, pathWithFocus)) {
                value = value.concat(['']);
            }
            this.setPath(newState.config, path, value);
            return newState;
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            processModalIsOpen: true,
        });

        console.log(this.state.config);
    }

    closeModal = () => {
        this.setState({
            processModalIsOpen: false,
            // outputFilename: null,
        });
    }

    normalize = (string) => {
        return string.toLowerCase().replace(' ', '_');
    }

    renderTabs = () => {
        if (this.state.formOnly) return;
        var options = [
            'Form',
            'YAML',
            'JSON',
            // 'Et cetera',
        ];
        return (
            <div className="tabs">
                <ul>
                    {options.map((label, i) =>
                        <li key={i} className={this.state.currentTab === this.normalize(label) ? 'is-active' : ''}>
                            <a onClick={() => { this.setState({ currentTab: this.normalize(label) }) }}>
                                {label}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    login = () => {
        netlifyAuth.authenticate(() => {
            console.log(netlifyAuth.user);
        });
    };

    renderForm = () => {
        return (
            <div className="level" >
                <form onSubmit={this.handleSubmit}>
                    {Object.keys(this.state.config).map((optionName, i) => {
                        return (
                            <div key={i} className="field">
                                <ConfigOptionInput name={optionName} />
                            </div>
                        );
                    })}
                </form>
            </div>
        );
    }

    renderYAML = () => {
        return (
            <div className="container" >
                <div className="level" >
                    <p>YAML is nice for humans...</p>
                </div>
                <div className="level" >
                    <div className="tile is-ancestor">
                        <div className="tile is-12">
                            <Textarea
                                className="input"
                                minRows={3}
                                defaultValue={this.state.yaml}
                                inputRef={this.yaml}
                                onBlur={this.saveYAML}
                            ></Textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    saveYAML = () => {
        try {
            const config = yaml.safeLoad(this.yaml.current.value);
            console.log(config);
            this.setState({ config });
        }
        catch (error) {
            console.log({error});
        }
    }

    renderJSON = () => {
        return (
            <div className="container" >
                <div className="level" >
                    <p>JSON is nice for computers...</p>
                </div>
                <div className="level" >
                    <div className="tile is-ancestor">
                        <div className="tile is-12">
                            <Textarea
                                className="input"
                                minRows={3}
                                defaultValue={this.state.json}
                                inputRef={this.json}
                                onBlur={this.saveJSON}
                            ></Textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    saveJSON = () => {
        if (this.json.current && this.json.current.value) {
            this.setState({ config: JSON.parse(this.json.current.value) });
        }
    }

    clearAll = () => {
        localStorage.removeItem('config');
        window.location = window.location.href.replace(window.location.search, '');
    }

    renderEtcetera = () => {
        return (
            <div className="container">
                <div className="field">
                    <label className="label">Share</label>
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <p className="control">
                                    <a className="button" data-clipboard-target="#share-text">Copy</a>
                                </p>
                                <p className="control is-expanded">
                                    {/* formOnly=1& */}
                                    <input id="share-text" className="input" type="text" readOnly value={window.location.href + '?json=' + encodeURIComponent(JSON.stringify(this.state.json))} />
                                </p>
                            </div>
                            <p className="help">You can use a service like <a href="https://bitly.com/">https://bitly.com/</a> to shorten the URL if you want. I won't be offended.</p>
                        </div>
                    </div>
                </div>

                <div className="level">
                    <button className="button is-danger" onClick={this.clearAll}>Clear All</button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppContext.Provider value={{
                config: this.state.config,
                handleInputChange: this.handleInputChange,
                refreshStringArray: this.refreshStringArray,
            }}>
                <div className="App container">
                    <div className="level">
                        <header className="App-header">
                            <h1 className="title is-1"><span className="color-brand">[}</span>ata<span className="color-brand">[}</span>oer</h1>
                        </header>
                    </div>

                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                {this.renderTabs()}
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <div className="field">
                                    <div className="field-body">
                                        <div className="field is-expanded">
                                            <div className="field has-addons">
                                                <p className="control">
                                                    <a className="button is-info is-small" data-clipboard-target="#share-text">Copy</a>
                                                </p>
                                                <p className="control is-expanded">
                                                    {/* formOnly=1& */}
                                                    <input id="share-text" className="input is-small" type="text" readOnly value={window.location.href + '?json=' + encodeURIComponent(JSON.stringify(this.state.json))} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="level-item">
                                <button className="button is-danger is-small" onClick={this.clearAll}>Clear All</button>
                            </div>
                        </div>
                    </div>


                    {this.state.currentTab === this.normalize('Form') ? this.renderForm() : ''}
                    {this.state.currentTab === this.normalize('YAML') ? this.renderYAML() : ''}
                    {this.state.currentTab === this.normalize('JSON') ? this.renderJSON() : ''}
                    {this.state.currentTab === this.normalize('Et cetera') ? this.renderEtcetera() : ''}

                    <div className={this.state.processModalIsOpen ? 'modal is-active' : 'modal'}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Processing...</p>
                                <button className="delete" aria-label="close" onClick={this.closeModal}></button>
                            </header>
                            <section className="modal-card-body">
                                <div className="level">
                                    <progress className="progress is-small is-dark" max="100" value="100"></progress>
                                </div>

                            </section>
                            <footer className="modal-card-foot">
                                <button className="button" onClick={this.closeModal}>Close</button>
                            </footer>
                        </div>
                    </div>
                </div>
            </AppContext.Provider>
        );
    }
}
